<template>
  <div>
    <div
      v-if="loading"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner label="Loading..." />
    </div>
    <div v-else-if="!loading && Object.keys(widget).length !== 0">
      <breadcrumbs
        :breadcrumb-items="breadcrumbItems"
      />
      <UserToolbar
        v-if="account && Object.keys(account).length !== 0"
        :account="account"
      />
      <b-card>
        <WidgetToolbar
          v-if="Object.keys(widget).length !== 0"
          :widget="widget"
          :widget-code="widgetCode($route.params.siteId, $route.params.widgetId)"
          closing
        />
      </b-card>
      <b-form @submit.prevent>
        <validation-observer ref="validationWidgetRules">
          <b-tabs
            pills
          >
            <b-tab>
              <template #title>
                <feather-icon
                  icon="BoxIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('notifications.widget.name') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('notifications.widget.name') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <div
                  v-if="typeChanged"
                  class="alert alert-danger"
                >
                  <div class="alert-body">
                    <strong>
                      {{ $t('notifications.widget.change_type', {oldType: $t(messagesChange[0]), newType: $t(messagesChange[1]) }) }}
                    </strong>
                  </div>
                </div>
                <template v-if="type !== 'builder'">
                  <b-row>
                    <b-col md="6">
                      <h5 class="mb-2">
                        {{ $t('widgets.type') }}
                      </h5>
                      <b-form-group
                        :label="$t('campaigns.type')"
                        label-for="i-type"
                      >
                        <b-form-select
                          v-model="type"
                          :options="typesFiltred"
                        />
                      </b-form-group>

                      <b-form-group
                        :label="$t('widgets.widgettitle')"
                        label-for="i-name"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('widgets.widgettitle')"
                          rules="required"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length == 0 || 'is-invalid'"
                          >
                            <b-input-group-prepend is-text>
                              <feather-icon icon="LayoutIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              id="i-name"
                              v-model="widget.title"
                              :placeholder="$t('widgets.widgettitle')"
                              :state="errors.length > 0 ? false:null"
                              autocomplete="name"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        :label="$t('campaigns.status')"
                        label-for="i-status"
                      >
                        <b-form-select
                          v-model="status"
                          :options="statusesTranslate"
                        />
                      </b-form-group>

                      <div
                        v-if="type === 'infinite'"
                      >
                        <b-form-group
                          :label="$t('widgets.scroll_count')"
                          label-for="i-value_calculation"
                        >
                          <b-input-group
                            class="input-group-merge"
                          >
                            <b-input-group-prepend is-text>
                              <feather-icon icon="MoreHorizontalIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              id="i-value_calculation"
                              v-model="widget.settings.infinite_scroll_number"
                              type="number"
                              :placeholder="$t('widgets.scroll_count')"
                            />
                          </b-input-group>
                        </b-form-group>
                      </div>
                      <div
                        v-if="type === 'slide'"
                      >
                        <b-form-group
                          :label="$t('widgets.slider_direction')"
                          label-for="i-carousel_direction"
                        >
                          <b-form-select
                            v-model="widget.settings.slide_direction"
                            :options="directions"
                          />
                        </b-form-group>

                        <b-form-group
                          :label="$t('widgets.widget_height')"
                          label-for="i-widget_height"
                        >
                          <b-input-group
                            class="input-group-merge"
                          >
                            <b-input-group-prepend is-text>
                              <feather-icon icon="Maximize2Icon" />
                            </b-input-group-prepend>
                            <b-form-input
                              id="i-widget_height"
                              v-model="widget.settings.slide_widget_height"
                              :placeholder="$t('widgets.widget_height')"
                            />
                          </b-input-group>
                        </b-form-group>

                        <b-form-group
                          :label="$t('widgets.slide_permove')"
                          label-for="i-slide_permove"
                        >
                          <b-input-group
                            class="input-group-merge"
                          >
                            <b-input-group-prepend is-text>
                              <feather-icon icon="ChevronRightIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              id="i-slide_permove"
                              v-model="widget.settings.slide_permove"
                              type="number"
                              :placeholder="$t('widgets.slide_permove')"
                            />
                          </b-input-group>
                        </b-form-group>
                        <b-form-group>
                          <b-form-checkbox
                            v-model="widget.settings.slide_autoSize"
                            value="true"
                          >
                            {{ $t("widgets.slide_autoSize") }}
                          </b-form-checkbox>
                        </b-form-group>
                        <div
                          v-if="!widget.settings.slide_autoSize"
                        >
                          <b-form-group
                            :label="$t('widgets.slide_load_limit')"
                            label-for="i-slide_load_limit"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="Maximize2Icon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-slide_load_limit"
                                v-model="widget.settings.slide_load_limit"
                                :placeholder="$t('widgets.slide_load_limit')"
                              />
                            </b-input-group>
                          </b-form-group>
                        </div>
                      </div>
                    </b-col>
                    <b-col
                      v-if="type === 'position_fixed'"
                      md="6"
                    >
                      <h5 class="mt-2 mb-2">
                        {{ $t('widgets.fixed_setup') }}
                      </h5>
                      <b-form-group
                        :label="$t('widgets.widget_position')"
                        label-for="i-widget_position"
                      >
                        <b-form-select
                          v-model="widget.settings.widget_position"
                          :options="widget_positions"
                        />
                      </b-form-group>
                      <b-form-group
                        :label="$t('widgets.slider_direction')"
                        label-for="i-carousel_direction"
                      >
                        <b-form-select
                          v-model="widget.settings.slide_direction"
                          :options="directions"
                        />
                      </b-form-group>
                      <b-form-group
                        :label="$t('widgets.widgetwidth')"
                        label-for="i-block_width"
                      >
                        <b-input-group
                          class="input-group-merge"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="Maximize2Icon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-block_width"
                            v-model="widget.settings.block_width"
                            type="number"
                            :placeholder="$t('widgets.widgetwidth')"
                          />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group
                        :label="$t('widgets.widget_height')"
                        label-for="i-widget_height"
                      >
                        <b-input-group
                          class="input-group-merge"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="Maximize2Icon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-widget_height"
                            v-model="widget.settings.slide_widget_height"
                            type="number"
                            :placeholder="$t('widgets.widget_height')"
                          />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group
                        :label="$t('widgets.fontsize')"
                        label-for="i-block_title_font_size"
                      >
                        <b-input-group
                          class="input-group-merge"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="Maximize2Icon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-block_title_font_size"
                            v-model="widget.settings.block_title_font_size"
                            :placeholder="$t('widgets.fontsize')"
                          />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <b-form-checkbox
                          v-model="widget.settings.block_img"
                          value="true"
                        >
                          {{ $t("widgets.hide") }} {{ $t("widgets.image").toLowerCase() }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="type !== 'position_fixed' && type !== 'builder'"
                      md="6"
                    >
                      <h5 class="mb-2">
                        {{ $t("widgets.appearance") }}
                      </h5>
                      <app-collapse
                        type="border"
                      >
                        <app-collapse-item
                          :title="$t('widgets.mainsettings')"
                        >
                          <b-form-group
                            :label="$t('widgets.widgetwidth')"
                            label-for="i-block_width"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="Maximize2Icon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_width"
                                v-model="widget.settings.block_width"
                                :placeholder="$t('widgets.widgetwidth')"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            :label="$t('widgets.columnwidth')"
                            label-for="i-block_item_size"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="Maximize2Icon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_item_size"
                                v-model="widget.settings.block_item_size"
                                :placeholder="$t('widgets.columnwidth')"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            :label="$t('widgets.guidancecolor')"
                            label-for="i-block_hover_color"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="ApertureIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_hover_color"
                                v-model="widget.settings.block_hover_color"
                                :placeholder="$t('widgets.guidancecolor')"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            :label="$t('widgets.font')"
                            label-for="i-block_title_font"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="TypeIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_title_font"
                                v-model="widget.settings.block_title_font"
                                :placeholder="$t('widgets.font')"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            :label="$t('widgets.fontsize')"
                            label-for="i-block_title_font_size"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="Maximize2Icon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_title_font_size"
                                v-model="widget.settings.block_title_font_size"
                                :placeholder="$t('widgets.fontsize')"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            v-if="authUserData.role.includes('admin')"
                          >
                            <b-form-checkbox
                              v-model="widget.settings.block_logo"
                              value="true"
                            >
                              {{ $t('widgets.hidelogo') }}
                            </b-form-checkbox>
                          </b-form-group>
                          <b-form-group>
                            <b-form-checkbox
                              v-model="widget.settings.article_target_blank"
                              value="true"
                            >
                              {{ $t("widgets.article-target-blank") }}
                            </b-form-checkbox>
                          </b-form-group>
                        </app-collapse-item>
                        <app-collapse-item
                          :title="$t('widgets.stroke')"
                        >
                          <b-form-group
                            :label="$t('widgets.thickness')"
                            label-for="i-block_border_width"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="SquareIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_border_width"
                                v-model="widget.settings.block_border_width"
                                type="number"
                                :placeholder="$t('widgets.thickness')"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            :label="$t('widgets.style')"
                            label-for="i-block_border_style"
                          >
                            <b-form-select
                              v-model="widget.settings.block_border_style"
                              :options="borderStyle"
                            />
                          </b-form-group>
                          <b-form-group
                            :label="$t('widgets.color')"
                            label-for="i-block_border_color"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="ApertureIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_border_color"
                                v-model="widget.settings.block_border_color"
                                :placeholder="$t('widgets.color')"
                              />
                            </b-input-group>
                          </b-form-group>
                        </app-collapse-item>
                        <app-collapse-item
                          :title="$t('widgets.title')"
                        >
                          <b-form-group>
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="TypeIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_title_label"
                                v-model="widget.settings.block_title_label"
                                :placeholder="$t('widgets.title')"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            :label="$t('widgets.color')"
                            label-for="i-block_name_color"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="ApertureIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_name_color"
                                v-model="widget.settings.block_name_color"
                                placeholder="#000000"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            :label="$t('widgets.background')"
                            label-for="i-block_name_bg"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="ApertureIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_name_bg"
                                v-model="widget.settings.block_name_bg"
                                placeholder="#000000"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group>
                            <b-form-checkbox
                              v-model="widget.settings.block_name"
                              value="true"
                            >
                              {{ $t("widgets.hide") }}
                            </b-form-checkbox>
                          </b-form-group>
                        </app-collapse-item>
                        <app-collapse-item
                          :title="$t('widgets.text')"
                        >
                          <b-form-group
                            :label="$t('widgets.alignment')"
                            label-for="i-block_title_align"
                          >
                            <b-form-select
                              v-model="widget.settings.block_title_align"
                              :options="alignment"
                            />
                          </b-form-group>
                          <b-form-group
                            :label="$t('widgets.color')"
                            label-for="i-block_title_color"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="ApertureIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_title_color"
                                v-model="widget.settings.block_title_color"
                                placeholder="#000000"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            v-b-tooltip.hover.top="`${$t('widgets.maxlength-help')}`"
                            :label="$t('widgets.maxlength')"
                            label-for="i-block_title_len"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="Maximize2Icon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_title_len"
                                v-model="widget.settings.block_title_len"
                                type="number"
                                placeholder="70"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            v-b-tooltip.hover.top="`${$t('widgets.circumcision-help')}`"
                            :label="$t('widgets.circumcision')"
                            label-for="i-block_title_suffix"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="TerminalIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_title_suffix"
                                v-model="widget.settings.block_title_suffix"
                                placeholder="..."
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group>
                            <b-form-checkbox
                              v-model="widget.settings.block_title"
                              value="true"
                            >
                              {{ $t("widgets.hide") }}
                            </b-form-checkbox>
                          </b-form-group>
                        </app-collapse-item>
                        <app-collapse-item
                          :title="$t('widgets.image')"
                        >
                          <b-form-group
                            :label="$t('widgets.width')"
                            label-for="i-block_img_width"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="Maximize2Icon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_img_width"
                                v-model="widget.settings.block_img_width"
                                type="number"
                                placeholder="220"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            :label="$t('widgets.height')"
                            label-for="i-block_img_height"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="Maximize2Icon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-block_img_height"
                                v-model="widget.settings.block_img_height"
                                type="number"
                                placeholder="150"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group>
                            <b-form-checkbox
                              v-model="widget.settings.block_img"
                              value="true"
                            >
                              {{ $t("widgets.hide") }}
                            </b-form-checkbox>
                          </b-form-group>
                        </app-collapse-item>
                        <app-collapse-item
                          v-if="type !== 'slide'"
                          :title="$t('widgets.source')"
                        >
                          <b-form-group
                            :label="$t('widgets.source_title')"
                            label-for="i-block_source_title"
                          >
                            <b-form-input
                              id="i-block_source_title"
                              v-model="widget.settings.block_source_title"
                              :placeholder="$t('widgets.source_title')"
                            />
                          </b-form-group>
                          <b-form-group
                            :label="$t('widgets.alignment')"
                            label-for="i-block_source_align"
                          >
                            <b-form-select
                              v-model="widget.settings.block_source_align"
                              :options="alignment"
                            />
                          </b-form-group>
                          <b-form-group>
                            <b-form-checkbox
                              v-model="widget.settings.block_source"
                              value="true"
                            >
                              {{ $t("widgets.hide") }}
                            </b-form-checkbox>
                          </b-form-group>
                        </app-collapse-item>
                        <app-collapse-item
                          v-if="type !== 'slide'"
                          :title="$t('widgets.slidersettings')"
                        >
                          <b-form-group
                            :label="$t('widgets.slider_timer')"
                            label-for="i-slider_timer"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="WatchIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-slider_timer"
                                v-model="widget.settings.slider_timer"
                                type="number"
                                placeholder="70"
                              />
                            </b-input-group>
                            <small>{{ $t("widgets.slidertimer-helptext") }}</small>
                          </b-form-group>
                          <b-form-group
                            :label="$t('widgets.slider_max_times')"
                            label-for="i-slider_max_times"
                          >
                            <b-input-group
                              class="input-group-merge"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="ColumnsIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-slider_max_times"
                                v-model="widget.settings.slider_max_times"
                                type="number"
                                placeholder="70"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group>
                            <b-form-checkbox
                              v-model="widget.settings.is_slider"
                              value="true"
                            >
                              {{ $t("widgets.is_slider") }}
                            </b-form-checkbox>
                          </b-form-group>
                        </app-collapse-item>
                        <app-collapse-item
                          :title="$t('widgets.ownstyles')"
                        >
                          <b-form-group>
                            <b-input-group>
                              <b-form-textarea
                                id="i-custom_css"
                                v-model="widget.settings.custom_css"
                                :placeholder="$t('widgets.ownstyles')"
                                rows="3"
                                max-rows="8"
                              />
                            </b-input-group>
                          </b-form-group>
                        </app-collapse-item>
                      </app-collapse>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      md="12"
                      class="d-flex align-items-center justify-content-between mt-1"
                    >
                      <div>
                        <b-button
                          v-if="authUserData.role.includes('admin')"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="info"
                          class="mr-0 mr-sm-1"
                          :disabled="disablePreviewButton"
                          @click="openPreview2(true)"
                        >
                          <feather-icon
                            icon="EyeIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">{{ $t('widgets.preview') }}</span>
                        </b-button>
                      </div>
                      <div>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-secondary"
                          class="mr-0 mr-sm-1"
                          :to="{ path: `/users/${site.user_id}/sites/${site._id}` }"
                        >
                          <feather-icon
                            icon="ChevronLeftIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">{{ $t('form.back') }}</span>
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="widgetUpdate"
                        >
                          <span class="align-middle">{{ $t('form.save') }}</span>
                          <feather-icon
                            icon="ChevronRightIcon"
                            class="ml-50"
                          />
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </template>
                <template v-if="type === 'builder'">
                  <WidgetBuilder
                    :type="type"
                    :types="typesFiltred"
                    :status="status"
                    :statuses="statusesTranslate"
                    :widget="widget"
                    :widget-settings="widgetTypeSettings.builder"
                    @changeWidgetModel="changeWidgetModel($event)"
                    @setData="widgetSetData($event)"
                    @saveData="widgetSaveData($event)"
                    @widgetElements="syncElements($event)"
                  />
                </template>
              </b-card>
            </b-tab>
            <b-tab v-if="authUserData.role.includes('admin')">
              <template #title>
                <feather-icon
                  icon="ShieldIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('sites.adminsettings') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('Administrator') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <b-form-checkbox
                        v-model="is_moderate"
                        value="1"
                      >
                        {{ $t('sites.moderated') }}
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox
                        v-model="nofollow"
                        value="1"
                      >
                        {{ $t('Nofollow') }}
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox
                        v-model="stats_visible"
                        value="1"
                      >
                        {{ $t('sites.stats_visible') }}
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox
                        v-model="strict_items_amount"
                        value="1"
                      >
                        {{ $t('sites.strict_items_amount') }}
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      :label="$t('widgets.marketplace_url')"
                      label-for="i-marketplace_url"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LinkIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-marketplace_url"
                          v-model="widget.marketplace_url"
                          :placeholder="$t('widgets.marketplace_url')"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('widgets.criteoadstype')"
                      label-for="i-criteo_ads_type"
                    >
                      <b-form-select
                        v-model="criteo_ads_type"
                        :options="criteo_ads_types"
                      />
                    </b-form-group>
                    <app-collapse
                      class="mb-1"
                      accordion
                      type="border"
                    >
                      <app-collapse-item
                        title="Prebid"
                      >
                        <b-row class="mb-1 align-items-center">
                          <b-col sm="6">
                            <div
                              class="d-flex card-box align-items-center"
                            >
                              <label for="prebid_enable">
                                {{ $t('sites.status') }}
                              </label>
                              <b-form-checkbox
                                id="prebid_enable"
                                :checked="widget.prebid_enable"
                                name="check-button"
                                switch
                                @change="value => value ? widget.prebid_enable = true : widget.prebid_enable = false"
                              />
                            </div>
                          </b-col>
                          <b-col sm="6">
                            <div
                              class="d-flex card-box align-items-center"
                            >
                              <label for="i-prebid_placements">
                                Placements
                              </label>
                              <b-form-input
                                id="i-prebid_placements"
                                v-model.number="widget.prebid_placements"
                                type="number"
                                placeholder="0"
                              />
                            </div>
                          </b-col>
                        </b-row>
                        <div
                          v-for="prebid_item in widget.prebid_settings"
                          :key="prebid_item.name"
                          class="link-card align-items-start w-100"
                        >
                          <div class="link-card-box">
                            <label :for="`prebid${prebid_item.name}`">
                              {{ prebid_item.name }}
                            </label>
                            <b-row
                              v-if="prebid_item.params.length"
                              class="mt-25 mb-25"
                            >
                              <b-col
                                v-for="prebid_item_param in prebid_item.params"
                                :key="prebid_item_param.key"
                                sm="12"
                                md="6"
                              >
                                <b-form-input
                                  :id="`i-${prebid_item_param.key}`"
                                  v-model="prebid_item_param.value"
                                  :placeholder="prebidPlaceholder(prebid_item_param.key)"
                                />
                              </b-col>
                            </b-row>
                          </div>
                          <b-form-checkbox
                            :id="`prebid${prebid_item.name}`"
                            :checked="Boolean(prebid_item.status === 'active')"
                            name="check-button"
                            switch
                            @change="value => value ? prebid_item.status = 'active' : prebid_item.status = 'inactive'"
                          />
                        </div>
                      </app-collapse-item>
                      <app-collapse-item
                        :title="$t('widgets.earns.title')"
                      >
                        <b-form-group
                          :label="$t('widgets.earns.processor')"
                          label-for="i-ern_status"
                        >
                          <b-form-select
                            v-model="widget.earn.processor"
                            :options="chargingType"
                          />
                        </b-form-group>
                        <b-form-group
                          :label="$t('widgets.earns.value')"
                          label-for="i-value_calculation"
                        >
                          <b-input-group
                            class="input-group-merge"
                          >
                            <b-input-group-prepend is-text>
                              <feather-icon icon="CreditCardIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              id="i-value_calculation"
                              v-model="widget.earn.value"
                              :placeholder="$t('widgets.earns.value')"
                            />
                          </b-input-group>
                        </b-form-group>
                      </app-collapse-item>
                      <app-collapse-item
                        title="Interstitial"
                      >
                        <b-form-group>
                          <b-form-checkbox
                            v-model="is_interstitial"
                            value="1"
                          >
                            {{ $t('form.enable') }}
                          </b-form-checkbox>
                        </b-form-group>
                        <b-row>
                          <b-col sm="6">
                            <label for="i-interstitial_time">
                              {{ $t('widgets.interstitial.time') }}
                            </label>
                            <b-form-input
                              id="i-interstitial_time"
                              v-model="widget.interstitial.time"
                              placeholder="3600000"
                              :disabled="is_interstitial.length === 0"
                            />
                          </b-col>
                          <b-col sm="6">
                            <label for="i-interstitial_page_count">
                              {{ $t('widgets.interstitial.page_count') }}
                            </label>
                            <b-form-input
                              id="i-interstitial_page_count"
                              v-model="widget.interstitial.page_count"
                              placeholder="3"
                              :disabled="is_interstitial.length === 0"
                            />
                          </b-col>
                        </b-row>
                      </app-collapse-item>
                      <app-collapse-item
                        title="In article (Demo)"
                      >
                        <InArticle
                          :inarticle-data="inarticle"
                          :prebid-enable="widget.prebid_enable"
                          @update-inarticle="updateInarticle($event, inarticle)"
                        />
                      </app-collapse-item>
                    </app-collapse>
                  </b-col>
                  <b-col md="6">
                    <div v-if="extra_processors">
                      <b-form-group
                        :label="$t('sites.algorithm')"
                        label-for="i-extra_processors"
                      >
                        <div
                          v-for="extra_processor, count in extra_processors"
                          :key="extra_processor.value"
                          class="d-flex mb-1"
                        >
                          <b-form-select
                            :ref="`extra_processor${count}`"
                            :value="extra_processor"
                            :options="processors"
                            class="select-append"
                            @change="extraProcessorUpdate($event, count)"
                          />
                          <b-button
                            variant="danger"
                            @click="extraProcessorRemove(count)"
                          >
                            <feather-icon
                              icon="Trash2Icon"
                              class="cursor-pointer"
                            />
                          </b-button>
                        </div>
                      </b-form-group>
                      <div class="d-flex justify-content-between mb-2">
                        <b-button
                          variant="primary"
                          @click="extraProcessorAdd()"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="cursor-pointer"
                          />
                        </b-button>
                        <div class="pt-1">
                          {{ $t('Total') }}: {{ extra_processors.length }}
                        </div>
                      </div>
                    </div>

                    <div v-if="widget.processors">
                      <b-form-group
                        :label="$t('sites.widgetformation')"
                        label-for="i-processors"
                      >
                        <div
                          v-for="processor, count in widget.processors"
                          :key="processor.value"
                          class="d-flex mb-1"
                        >
                          <b-form-select
                            :ref="`processor${count}`"
                            :value="processor"
                            :options="processors"
                            class="select-append"
                            @change="processorUpdate($event, count)"
                          />
                          <b-button
                            variant="danger"
                            @click="processorRemove(count)"
                          >
                            <feather-icon
                              icon="Trash2Icon"
                              class="cursor-pointer"
                            />
                          </b-button>
                        </div>
                        <template v-if="widget.prebid_enable && widget.prebid_placements !== null && widget.prebid_placements !== ''">
                          <div
                            v-for="count in parseInt(widget.prebid_placements, 10)"
                            :key="`prebid-${count}`"
                            class="d-flex mb-1"
                          >
                            <b-form-select
                              disabled
                              value="prebid"
                              :options="[{ value: 'prebid', text: 'Prebid' }]"
                              class="select-append"
                            />
                            <b-button
                              disabled
                              variant="danger"
                            >
                              <feather-icon
                                icon="Trash2Icon"
                              />
                            </b-button>
                          </div>
                        </template>
                      </b-form-group>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <b-button
                        :disabled="widget.inarticle && widget.inarticle.enable"
                        variant="primary"
                        @click="processorAdd()"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="cursor-pointer"
                        />
                      </b-button>
                      <div class="pt-1">
                        {{ $t('Total') }}: {{ widgetProcessorsLength }}
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-between mt-1"
                  >
                    <div>
                      <b-button
                        v-if="authUserData.role.includes('admin')"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="info"
                        class="mr-0 mr-sm-1"
                        :disabled="disablePreviewButton"
                        @click="openPreview2(true)"
                      >
                        <feather-icon
                          icon="EyeIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">{{ $t('widgets.preview') }}</span>
                      </b-button>
                    </div>
                    <div>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-secondary"
                        class="mr-0 mr-sm-1"
                        :to="{ path: `/users/${site.user_id}/sites/${site._id}` }"
                      >
                        <feather-icon
                          icon="ChevronLeftIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">{{ $t('form.back') }}</span>
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="widgetUpdate"
                      >
                        <span class="align-middle">{{ $t('form.save') }}</span>
                        <feather-icon
                          icon="ChevronRightIcon"
                          class="ml-50"
                        />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import WidgetToolbar from '@/views/dashboard/widgets/WidgetToolbar.vue'
import useJwt from '@/auth/jwt/useJwt'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import options from '@/libs/options'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import {
  BRow,
  BCol,
  BSpinner,
  BTabs,
  BTab,
  BCard,
  BForm,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BFormCheckbox,
  BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue'
import {
  required,
  integer,
  min,
} from '@validations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import WidgetBuilder from '@/views/dashboard/widgets/WidgetBuilder.vue'
import InArticle from '@/views/dashboard/widgets/components/InArticle.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    Breadcrumbs,
    BRow,
    BCol,
    BSpinner,
    BTabs,
    BTab,
    BCard,
    BForm,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    BFormTextarea,
    UserToolbar,
    WidgetToolbar,
    WidgetBuilder,
    InArticle,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: true,
      load: 0,
      disablePreviewButton: false,
      pageTitle: '',
      min,
      required,
      integer,
      checking: '',
      widget: [],
      widgetDefaults: {
        block_hover_color: '#E0E0E0',
        block_img_align: 'Center',
        block_border_color: '#FFFFFF',
        block_title_align: 'Left',
        custom_css: '#wblock .item .wbsource {\nfont-family: Arial; \nfont-weight: bold;\n}',
        block_logo: true,
        block_img_width: '220',
        block_img_height: '150',
        block_source: false,
        block_name_bg: '#FFFFFF',
        block_border_width: '0',
        block_width: '100%',
        block_name_color: '#FFFFFF',
        block_border_style: 'solid',
        block_name: true,
        block_title_label: '',
        block_item_size: '33%',
        block_title_font: 'auto',
        block_title: false,
        block_title_len: '',
        block_title_suffix: '',
        block_source_title: '',
        block_source_align: 'Left',
        block_title_color: '#000000',
        block_title_font_size: '14px',
        block_img: false,
        nofollow: false,
        is_slider: false,
        slider_timer: 30,
        slider_max_times: 10,
        article_target_blank: false,
        stats_visible: false,
        slide_direction: 'ltr',
        widget_position: 'top_left',
        rewind: true,
        slide_widget_height: '240px',
        slide_load_limit: '3',
        infinite_scroll_number: '5',
        slide_autoSize: true,
        slide_permove: 1,
      },
      widgetBuilder: {
        name: '',
        block_source_title: '',
        type: 0,
        rows: 3,
        cols: 3,
        adaptation_type: 2,
        fixed: 0,
        table_padding: 0,
        text_align: 'left',
        table_width: 100,
        table_width_units: 1,
        table_height: 0,
        table_height_units: 0,
        table_bg_color: '#ffffff',
        table_cellspacing_h: 5,
        table_cellspacing_v: 5,
        td_padding: 1,
        td_bg_color: '#ffffff',
        td_border_size: 0,
        td_border_type: 'solid',
        td_border_color: '#cccccc',
        td_border_radius: 0,
        img_wide: 1,
        img_width: 180,
        img_border_type: 'solid',
        img_border_size: 0,
        img_border_color: '#ffffff',
        img_text_indent: 5,
        img_border_radius: 0,
        is_infinity: false,
        desc_font: 'sans-serif',
        desc_font_weight: 'bold',
        desc_text_padding: 5,
        desc_text_decorat: 0,
        desc_font_size: 15,
        desc_font_color: '#000000',
        desc_position: 1,
        style: '',
      },
      widgetTypeSettings: {
        default: null,
        infinite: null,
        slide: null,
        position_fixed: null,
        builder: null,
      },
      widgetBuilderProcessors: [],
      status: {},
      criteo_ads_type: 'banner',
      type: 'builder',
      site: [],
      account: {},
      is_moderate: [],
      is_interstitial: [],
      is_inarticle: [],
      strict_items_amount: [],
      nofollow: [],
      stats_visible: [],
      slide_autoSize: [],
      breadcrumbItems: [],
      rewind: true,
      types: [
        { value: 'builder', text: 'selects.widgettype.builder' },
        { value: 'default', text: 'selects.widgettype.default' },
        { value: 'infinite', text: 'selects.widgettype.infinite' },
        { value: 'slide', text: 'selects.widgettype.slide' },
        { value: 'position_fixed', text: 'selects.widgettype.position_fixed' },
      ],
      extra_processors: [
        'core.processors.random.base.ArticleRandom',
      ],
      processors: [
        { value: 'core.processors.random.base.ArticleRandom', text: this.$i18n.t('selects.processor.articlerandom') },
        { value: 'core.processors.relevant.base.ArticleRelevant', text: this.$i18n.t('selects.processor.articlerelevant') },
        { value: 'core.processors.random.base.ExchangeRandom', text: this.$i18n.t('selects.processor.exchangerandom') },
        { value: 'core.processors.random.base.BannerRandom', text: this.$i18n.t('selects.processor.bannerrandom') },
        { value: 'core.processors.banners.base.TopCTR', text: this.$i18n.t('selects.processor.topctr') },
        { value: 'core.processors.banners.base.AdsenseBannerRandom', text: this.$i18n.t('selects.processor.adsensebannerrandom') },
        { value: 'core.processors.relevant.base.ArticleLanguage', text: this.$i18n.t('selects.processor.articlelanguage') },
        { value: 'core.processors.random.base.PromotionPageRandom', text: this.$i18n.t('selects.processor.promotionpagerandom') },
        { value: 'core.processors.banners.base.ExternalAPI', text: this.$i18n.t('selects.processor.externalapi') },
        { value: 'core.processors.random.base.NewsRandom', text: this.$i18n.t('selects.processor.newsrandom') },
        { value: 'core.processors.banners.base.CPCFeedAPI', text: this.$i18n.t('selects.processor.cpcfeedapi') },
        { value: 'core.processors.banners.base.RevContent', text: 'RevContent' },
        { value: 'core.processors.random.base.VideoBannerRandom', text: this.$i18n.t('selects.processor.videobannerrandom') },
        // { value: 'core.processors.banners.base.Criteo', text: 'Criteo' },
        { value: 'core.processors.random.base.RssRandom', text: this.$i18n.t('selects.processor.rssrandom') },
        { value: 'core.processors.random.base.ExchangeSitesGroupRandom', text: this.$i18n.t('selects.processor.exchangesitesgrouprandom') },
        { value: 'core.processors.random.base.ExchangeOwnerRss', text: this.$i18n.t('selects.processor.exchangeownerrss') },
      ],
      chargingType: [
        { value: 'core.earns.Percent', text: this.$i18n.t('selects.finance.percent') },
        { value: 'core.earns.Fixed', text: this.$i18n.t('selects.finance.fixed') },
      ],
      alignment: [
        { value: 'Left', text: 'Left' },
        { value: 'Right', text: 'Right' },
        { value: 'Center', text: 'Center' },
        { value: 'Justify', text: 'Justify' },
      ],
      directions: [
        { value: 'ltr', text: 'Left to right' },
        { value: 'rtl', text: 'Right to left' },
        { value: 'ttb', text: 'Top to bottom' },
      ],
      widget_positions: [
        { value: 'top_left', text: 'Top left' },
        { value: 'top_right', text: 'Top right' },
        { value: 'bot_left', text: 'Bottom left' },
        { value: 'bot_right', text: 'Bottom right' },
      ],
      borderStyle: [
        { value: 'solid', text: 'Solid' },
        { value: 'dashed', text: 'Dashed' },
        { value: 'dotted', text: 'Dotted' },
        { value: 'groove', text: 'Groove' },
        { value: 'hidden', text: 'Hidden' },
      ],
      ...options,
      authUserData: getUserData(),
      typeChanged: false,
      startType: '',
      messagesChange: [],
      inarticle: {
        enable: false,
        container: '',
        tag: '',
        offset: 0,
        block_width: 300,
        block_height: 250,
        prebid_placements: 1,
      },
    }
  },
  computed: {
    statusesTranslate() {
      return this.statuses.map(s => {
        const item = {}
        item.value = s.value
        item.text = this.$i18n.t(`selects.statuses.${s.value}`)
        return item
      })
    },
    typesFiltred() {
      return this.types.map(t => ({ value: t.value, text: this.$i18n.t(t.text) }))
    },
    widgetProcessorsLength() {
      let total = this.widget.processors.length
      if (this.widget.prebid_enable && this.widget.prebid_placements !== null && this.widget.prebid_placements !== '') {
        total += parseInt(this.widget.prebid_placements, 10)
      }
      return total
    },
  },
  watch: {
    type: {
      handler(newType) {
        if (this.load) {
          if (newType !== 'builder') {
            if (this.widgetTypeSettings[newType] !== null) {
              this.widget.settings = this.widgetTypeSettings[newType]
            } else {
              this.widget.settings = this.widgetDefaults
            }
            if ((newType === 'slide') || (newType === 'position_fixed')) {
              this.widget.settings.block_title_color = '#ffffff'
              this.widget.settings.slider_timer = 0
            } else {
              this.widget.settings.block_title_color = '#000000'
              this.widget.settings.slider_timer = 30
            }
            if (newType === 'position_fixed') {
              this.widget.settings.block_title_color = '#ffffff'
              this.widget.settings.slider_timer = 0
              this.widget.settings.block_width = 200
              this.widget.settings.slide_widget_height = 300
            }
          } else if (this.startType !== 'builder' && newType === 'builder') {
            this.widgetTypeSettings[newType] = this.widgetBuilder
          }

          if (this.startType === 'builder') {
            this.messagesChange = [
              'selects.widgettype.builder',
              `selects.widgettype.${newType}`,
            ]
            this.typeChanged = true
          } else if (this.startType !== 'builder' && newType === 'builder') {
            this.messagesChange = [
              `selects.widgettype.${this.startType}`,
              `selects.widgettype.${newType}`,
            ]
            this.typeChanged = true
          } else {
            this.typeChanged = false
            this.messagesChange = []
          }

          if (newType === this.startType) { // remove message if not changed widget type
            this.typeChanged = false
            this.messagesChange = []
          }

          this.widget.type = newType
        }
        this.load = 1
      },
      deep: false,
    },
    'widget.prebid_enable': {
      handler(newValue) {
        if (this.widget.type === 'builder') {
          if (newValue) {
            const processorsLength = this.widgetProcessorsLength
            this.syncElements(processorsLength)
          } else {
            this.syncElements(this.widgetBuilderProcessors.length)
          }
        }
      },
      deep: false,
    },
    inarticle: {
      handler(newData) {
        if (newData && this.widget.type === 'builder') {
          let prebidPlacements = 0
          if (this.widget.prebid_enable && this.widget.prebid_placements !== null && this.widget.prebid_placements !== '') {
            prebidPlacements += parseInt(this.widget.prebid_placements, 10)
          }

          if (newData.enable) {
            this.widget.processors = [this.widgetBuilderProcessors[0]]
            this.syncElements(1 + prebidPlacements)
          } else {
            this.widget.processors = this.widgetBuilderProcessors
            this.syncElements(this.widgetBuilderProcessors.length + prebidPlacements)
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.getWidgetData()
  },
  methods: {
    async getWidgetData() {
      const responseData = await useJwt.getWidget(this.$route.params.widgetId)
      this.widget = responseData.data.widget || []
      if (Object.keys(this.widget).length !== 0) {
        if (this.widget.prebid_settings === undefined || (this.widget.prebid_settings && this.widget.prebid_settings.length === 0)) {
          this.widget.prebid_settings = [{
            name: 'Criteo',
            params: [],
            status: 'inactive',
          },
          {
            name: 'Avaz',
            params: [
              {
                key: 'site_id',
                value: '',
              },
              {
                key: 'placement_id',
                value: '',
              },
            ],
            status: 'inactive',
          }]
        }

        this.getUser(this.widget.user_id)

        if (this.widget.status !== '') {
          this.status = this.widget.status
        }

        if (this.widget.criteo_ads_type !== undefined) {
          this.criteo_ads_type = this.widget.criteo_ads_type
        }

        if (this.widget.extra_processors !== undefined) {
          this.extra_processors = this.widget.extra_processors
        }

        if (this.widget.type !== '') {
          this.startType = this.widget.type
          this.type = this.widget.type
          this.widgetTypeSettings[this.widget.type] = this.widget.settings
        }

        if (this.widget.strict_items_amount) {
          this.strict_items_amount.push(1)
        }

        if (this.widget.is_moderate) {
          this.is_moderate.push(1)
        }

        if (this.widget.settings.nofollow) {
          this.nofollow.push(1)
        }

        if (this.widget.settings.stats_visible) {
          this.stats_visible.push(1)
        }

        if (this.widget.settings.rewind) {
          this.rewind = this.widget.settings.rewind
        }

        if (this.widget.interstitial === undefined) {
          this.widget.interstitial = { active: false, time: '36e5', page_count: '3' }
        }

        if (this.widget.inarticle !== undefined) {
          this.inarticle = this.widget.inarticle
        }

        this.widgetBuilderProcessors = this.widget.processors

        if (this.widget.interstitial.active) {
          this.is_interstitial.push(1)
        }

        if (this.widget.settings.infinity === undefined || this.widget.settings.infinity === null) {
          this.widget.settings.infinity = { enable: false }
        }

        this.loading = false
      }
    },
    async getSiteData(id) {
      const responseData = await useJwt.getSite(id)
      this.site = responseData.data.site || []
      this.pageTitle = this.site.title
      this.setBreadcrumbs()
    },
    async getUser(id) {
      const responseData = await useJwt.getUser(id)
      this.account = responseData.data.account || {}
      this.getSiteData(this.widget.site_id)
    },
    setBreadcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$i18n.t('page.menu.users'),
          to: '/users/',
        },
        {
          text: this.account.name,
          to: `/users/${this.site.user_id}`,
        },
        {
          text: this.$i18n.t('page.menu.sites'),
          to: `/users/${this.site.user_id}/sites/`,
        },
        {
          text: this.site.title,
          to: `/users/${this.site.user_id}/sites/${this.site._id}`,
        },
        {
          text: this.$i18n.t('page.features.widgets'),
          to: `/users/${this.site.user_id}/sites/${this.site._id}/widgets/`,
        },
        {
          text: this.widget.title,
          to: `/users/${this.site.user_id}/sites/${this.site._id}/widgets/${this.widget._id}`,
        },
        {
          text: 'Settings',
          active: true,
        },
      ]
    },
    widgetSetData(data) {
      if (data) {
        this.widget = Object.assign(this.widget, data)

        if (this.widget.settings.img_wide) {
          this.widget.settings.block_img_width = 456
          this.widget.settings.block_img_height = 259
        } else {
          this.widget.settings.block_img_width = 304
          this.widget.settings.block_img_height = 304
        }

        this.widgetUpdate()
      }
    },
    widgetSaveData(data) {
      if (data) {
        this.widget = Object.assign(this.widget, data)

        if (this.widget.settings.img_wide) {
          this.widget.settings.block_img_width = 456
          this.widget.settings.block_img_height = 259
        } else {
          this.widget.settings.block_img_width = 304
          this.widget.settings.block_img_height = 304
        }

        this.widgetUpdate(false)
      }
    },
    widgetUpdate(redirect = true) {
      this.$refs.validationWidgetRules.validate().then(success => {
        if (success) {
          this.widget.status = this.status
          if (this.criteo_ads_type !== '') {
            this.widget.criteo_ads_type = this.criteo_ads_type
          }
          if (this.type !== '') {
            this.widget.type = this.type
          }
          this.widget.extra_processors = this.extra_processors
          this.widget.is_moderate = this.is_moderate
          this.widget.settings.nofollow = this.nofollow
          this.widget.settings.rewind = this.rewind
          this.widget.interstitial.active = this.is_interstitial
          this.widget.inarticle = this.inarticle
          this.widget.strict_items_amount = this.strict_items_amount
          this.widget.settings.stats_visible = Boolean(this.stats_visible.length)

          useJwt.setWidget(this.widget._id, this.widget)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$i18n.t('widgets.widget_saved'),
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              if (redirect) {
                if (this.typeChanged) {
                  this.$router.push(`/users/${this.widget.user_id}/sites/${this.widget.site_id}/widgets/${this.widget._id}?m=1`)
                } else {
                  this.$router.push({ name: 'dashboard-site-widgets' })
                }
              }
            }).catch(error => {
              if (error.response && error.response.status === 422) {
                if (error.response.data.errors.length) {
                  error.response.data.errors.forEach(err => {
                    const field = err.source.pointer.split('/')
                    this.$store.dispatch(
                      'error-store/showNotify',
                      {
                        title: `Error [${field[field.length - 1]}]`,
                        msg: err.detail,
                        variant: 'danger',
                      },
                      { root: true },
                    )
                    this.$refs.validationWidgetRules.setErrors({ [field[field.length - 1]]: err.detail })
                  })
                }
              }
              if (error.response && error.response.status === 500) {
                this.$store.dispatch(
                  'error-store/showNotify',
                  {
                    title: error.response.statusText,
                    msg: error.message,
                    variant: 'danger',
                  },
                  { root: true },
                )
              }
            })
        }
      })
    },
    extraProcessorAdd() {
      this.extra_processors.push(this.processors[0].value)
    },
    extraProcessorUpdate(event, index) {
      this.extra_processors[index] = event
    },
    extraProcessorRemove(index) {
      this.extra_processors.splice(index, 1)
    },
    processorAdd() {
      this.widget.processors.push(this.processors[0].value)
    },
    processorUpdate(event, index) {
      this.widget.processors[index] = event
    },
    processorRemove(index) {
      this.widget.processors.splice(index, 1)
    },
    syncElements(total) {
      let countProcessors = this.widget.processors.length

      if (this.widget.prebid_enable && this.widget.prebid_placements !== null && this.widget.prebid_placements !== '') {
        total -= parseInt(this.widget.prebid_placements, 0)
      }

      if (this.widget.inarticle && this.widget.inarticle.enable && countProcessors === 1) return

      if (total === countProcessors) return
      if (total > countProcessors) {
        while (total > countProcessors) {
          this.widget.processors.push(this.processors[0].value)
          countProcessors += 1
        }
      } else if (total < countProcessors) {
        while (total < countProcessors) {
          this.widget.processors.splice(countProcessors - 1, 1)
          countProcessors -= 1
        }
      }
    },
    // openPreview(siteId, widgetId) {
    //   this.disablePreviewButton = true
    //   window.open(`${jwtDefaultConfig.widgetPreview}/${siteId}/${widgetId}`, this.$i18n.t('widgets.preview'), 'width=800, height=600, resizable=yes')
    //   setTimeout(() => {
    //     this.disablePreviewButton = false
    //   }, 1000)
    // },
    async openPreview2(json = false) {
      this.disablePreviewButton = true
      await fetch(jwtDefaultConfig.widgetPreviewV2, {
        method: 'post',
        body: JSON.stringify({ site: this.site, widget: this.widget }),
      })
        .then(response => response.text())
        .then(data => {
          const jsonData = this.dataReplace(data)
          const randomId = Math.floor(Math.random() * (99999 - 9999 + 1) + 9999)
          const widgetCode = this.$route.params.widgetId && json === false
            ? this.widgetCode(this.site._id, this.widget._id)
            : this.widgetCode(this.site._id, randomId, jsonData)
          const winHtml = `<!DOCTYPE html><html><head><title>${this.$i18n.t('widgets.preview')}</title></head><body>${widgetCode}</body></html>`
          const windowsPreview = window.open('about:blank', this.$i18n.t('widgets.preview'), 'width=400, height=600, resizable=yes')
          windowsPreview.document.write(winHtml)
        })
      this.disablePreviewButton = false
    },
    dataReplace(data) {
      const replaceData = JSON.parse(data)
      const { custom_css } = replaceData.widget_settings
      replaceData.widget_settings.custom_css = custom_css.replace(/\n/g, '')
      return JSON.stringify(replaceData)
    },
    prebidPlaceholder(name) {
      if (name === 'site_id') {
        return 'pageId'
      }

      if (name === 'placement_id') {
        return 'impId'
      }

      return name
    },
    widgetCode(siteId, widgetId, data = '') {
      let widgetCode = `
        <div id="phoenix${widgetId}"></div>
        <script type="text/javascript" src="//phoenix-widget.com/static/js/jsonp_v3.js"><${''}/script>`
      if (this.widget.type === 'position_fixed') {
        widgetCode = `
          <div id="phoenix${widgetId}"></div>
          <script type="text/javascript" src="//phoenix-widget.com/static/js/widget_fixed.js"><${''}/script>`
      }
      if (data !== '') {
        widgetCode += `
          <script async type="text/javascript">
            new PhoenixWidget({
              siteId: "${this.site._id}",
              widgetId: "${widgetId}",
              preview: true,
              getData: '${data}'
            });
          <${''}/script>`
      } else {
        widgetCode += `
          <script async type="text/javascript">
            new PhoenixWidget({
              siteId: "${siteId}",
              widgetId: "${widgetId}",
            });
          <${''}/script>`
      }
      return widgetCode
    },
    changeWidgetModel(val) {
      if (val[0] && val[0] !== '') {
        const value = val[0]
        const modelName = val[1]
        this[modelName] = value
      }
    },
    updateInarticle(inarticleData) {
      if (this.widget.type === 'builder' && Object.keys(inarticleData).length !== 0) {
        this.inarticle = Object.assign(this.inarticle, inarticleData)
      }
    },
  },
}
</script>
<style scoped>
  .select-append {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .select-append + button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
</style>
