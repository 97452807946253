<template>
  <div>
    <b-form-group>
      <b-form-checkbox
        v-model="is_inarticle"
        value="1"
      >
        {{ $t('form.enable') }}
      </b-form-checkbox>
    </b-form-group>
    <b-row class="mb-2">
      <b-col sm="6">
        <label for="i-interstitial_time">
          Аrticle container
        </label>
        <b-form-input
          id="i-inarticle_container"
          v-model="inarticle.container"
          placeholder=".article"
          :disabled="is_inarticle.length === 0"
        />
      </b-col>
      <b-col sm="6">
        <div class="row">
          <b-col sm="6">
            <label for="i-inarticle_paragraph">
              Paragraph offset
            </label>
            <b-form-input
              id="i-inarticle_paragraph"
              v-model="inarticle.offset"
              placeholder="0"
              :disabled="is_inarticle.length === 0"
            />
          </b-col>
          <b-col sm="6">
            <label for="i-inarticle_tag">
              Tag (Optional)
            </label>
            <b-form-input
              id="i-inarticle_tag"
              v-model="inarticle.tag"
              placeholder="br"
              :disabled="is_inarticle.length === 0"
            />
          </b-col>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group
          :label="$t('widgets.width')"
          label-for="i-inarticle_block_width"
        >
          <b-input-group
            class="input-group-merge"
            :class="{disabled: is_inarticle.length === 0}"
          >
            <b-input-group-prepend is-text>
              <feather-icon icon="Maximize2Icon" />
            </b-input-group-prepend>
            <b-form-input
              id="i-inarticle_block_width"
              v-model="inarticle.block_width"
              type="number"
              placeholder="300"
              :disabled="is_inarticle.length === 0"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          :label="$t('widgets.height')"
          label-for="i-block_img_height"
          :class="{disabled: is_inarticle.length === 0}"
        >
          <b-input-group
            class="input-group-merge"
            :class="{disabled: is_inarticle.length === 0}"
          >
            <b-input-group-prepend is-text>
              <feather-icon icon="Maximize2Icon" />
            </b-input-group-prepend>
            <b-form-input
              id="i-block_img_height"
              v-model="inarticle.block_height"
              type="number"
              placeholder="250"
              :disabled="is_inarticle.length === 0"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
  },
  props: {
    inarticleData: {
      type: Object,
      required: true,
    },
    prebidEnable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inarticle: {},
      is_inarticle: [],
    }
  },
  watch: {
    inarticle: {
      handler(newData) {
        if (newData) {
          this.$emit('update-inarticle', this.inarticle)
        }
      },
      deep: true,
    },
    is_inarticle: {
      handler(newIsInarticle) {
        this.inarticle.enable = Boolean(newIsInarticle.length)
      },
      deep: false,
      immediate: true,
    },
  },
  created() {
    this.inarticle = this.inarticleData
    if (this.inarticle.enable) {
      this.is_inarticle.push(1)
    } else {
      this.is_inarticle = []
    }
  },
}
</script>
