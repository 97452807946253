<template>
  <div>
    <div
      id="previev321"
      class="pnx-infinity"
    >
      <div
        v-for="(teaser, index) in teasers"
        :key="index"
        class="pnx-grid-item pnx-infinity-item"
      >
        <a
          href="#"
          class="pnx-cont"
          @click.prevent
        >
          <div style="display:block !important;">
            <div class="pnx-imgbox">
              <img
                :src="getTeaserImage(teaser)"
                class="pnx-img"
                :alt="teaser.desc || 'Teaser Image'"
              >
            </div>
          </div>
          <div
            v-if="teaser.desc"
            class="pnx-desc"
          >
            <span>{{ teaser.desc }}</span>
          </div>
          <div
            v-if="teaser.source"
            class="pnx-source"
          >
            {{ teaser.source }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetBuilder321Preview',
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      teasers: [],
      teasersTitle: ['The Mysterious Tale of the Disastrous Summer Vacation', 'The Exploding Labyrinth of Dark Deceit', 'The Spectacle of Golden Possibilities', 'The Curse of the Blinking Shadow', 'The Mystery of the Shifting Sands', 'The Lost Kingdom of the Everlasting Wind', 'The Quest for the Silver Fire', 'The Prophecy of the Flaming Fog', 'The Revenge of the Thundering Flame', 'The Legend of the Eerie Stream', 'The Fear of the Forbidden Garden', 'The Search for the Celestial Flame', 'The Adventure of the Crimson Moon', 'The Secret of the Mystic Maze'],
      teasersLimit: 6,
    }
  },
  computed: {
    teasersPlaces() {
      return this.blockData.rows * this.blockData.cols
    },
  },
  watch: {
    blockData: {
      handler() {
        const previev321 = document.getElementById('previev321')
        previev321.querySelector('style').remove()
        this.$nextTick().then(() => {
          this.addStyle(this.blockStyles())
        })
      },
      deep: true,
    },
  },
  created() {
    this.setDefaultTeasersData()
  },
  mounted() {
    this.addStyle(this.blockStyles())
  },
  methods: {
    setDefaultTeasersData() {
      const min = 0
      const max = this.teasersLimit - 1
      for (let i = min; i < this.teasersLimit; i += 1) {
        const randomIndex = Math.floor(Math.random() * max) + min
        this.teasers.push({
          img: [`https://img-cdn.phoenix-widget.com/assets/widget/img${randomIndex}-s.jpg`, `https://img-cdn.phoenix-widget.com/assets/widget/img${randomIndex}-r.jpg`],
          desc: this.teasersTitle[randomIndex],
          url: '#',
        })
      }
      this.setRandomSource()
    },
    setRandomSource() {
      for (let i = 0; i < this.teasersPlaces; i += 1) {
        this.teasers[i].source = ''
      }
      if (this.blockData.block_source_title) {
        const totalSorces = this.blockData.rows
        for (let y = 0; y < totalSorces; y += 1) {
          const randomTeaserIndex = Math.floor(Math.random() * (this.teasersPlaces - 1))
          this.teasers[randomTeaserIndex].source = this.blockData.block_source_title
        }
      }
    },
    getTeaserImage(teaser) {
      return teaser.img && teaser.img[this.blockData.img_wide] ? teaser.img[this.blockData.img_wide] : ''
    },
    blockStyles() {
      const baseStyles = `.pnx-infinity{width:${this.blockData.table_width}${this.blockData.table_width_units ? '%' : 'px'};${this.blockData.fixed && this.blockData.table_height ? `height: ${this.blockData.table_height}${this.blockData.table_height_units ? '%' : 'px'}` : ''};border-spacing: ${this.blockData.table_cellspacing_h}px ${this.blockData.table_cellspacing_v}px!important;background-color:${this.blockData.table_bg_color}!important;padding: ${this.blockData.table_padding}px;}
      .pnx-grid {display: grid;grid-template-columns: repeat(${this.blockData.cols}, minmax(0, 1fr));grid-template-rows: repeat(${this.blockData.rows}, 1fr);padding: ${this.blockData.table_padding}px;background-color:${this.blockData.table_bg_color ? this.blockData.table_bg_color : 'transparent'}!important;gap: ${this.blockData.table_cellspacing_h}px ${this.blockData.table_cellspacing_v}px!important;}
      .pnx-grid-item {text-align: center !important;padding: ${this.blockData.table_cellspacing_v ? this.blockData.table_cellspacing_v : 0}px ${this.blockData.table_cellspacing_h ? this.blockData.table_cellspacing_h : 0}px;background-color:${this.blockData.td_bg_color} !important;vertical-align:top;border: ${this.blockData.td_border_size}px ${this.blockData.td_border_type} ${this.blockData.td_border_color};border-radius: ${this.blockData.td_border_radius}px;line-height:0;}
      .pnx-cont{text-decoration:none;display:block !important;-ms-word-break:break-all;word-break:break-all;word-break:break-word;-webkit-hyphens:auto;-moz-hyphens:auto;hyphens:auto;margin:auto;padding:0;text-align: ${this.blockData.text_align};position:relative;max-width: ${Math.round(this.blockData.img_width * (this.blockData.img_wide === 1 ? 1.33 : 1))}px;}
      .pnx-img{max-width: 100%;max-height: 100%;border: 0;margin: auto;padding: 0;${this.blockData.desc_position === 0 ? '' : 'position: absolute;'}top: 0;right: 0;bottom: 0;left: 0;width: 100%;height: auto;border-radius: ${this.blockData.img_border_radius}px;}
      .pnx-imgbox{float:none;height:0;display:block;position:relative;box-sizing: content-box;border: ${this.blockData.img_border_size}px ${this.blockData.img_border_type} ${this.blockData.img_border_color};padding: ${(this.blockData.img_wide === 1 ? 56.58 : 100)}% 0 0 0;margin: ${this.blockData.text_align === 'center' ? `0 auto  ${this.blockData.img_text_indent}px auto` : `0 0 ${this.blockData.img_text_indent}px 0`};border-radius: ${this.blockData.img_border_radius === 0 ? `${this.blockData.img_border_radius}` : `${parseInt(this.blockData.img_border_radius, 10) + parseInt(this.blockData.img_border_size, 10)}`}px;}
      .pnx-desc{${this.blockData.desc_font_size ? `font-size: ${this.blockData.desc_font_size}px;` : ''} font-family: ${this.blockData.desc_font && this.blockData.desc_font !== '' ? this.blockData.desc_font : 'inherit'};color:${this.blockData.desc_font_color};text-decoration: ${this.blockData.desc_text_decorat === 1 ? 'underline' : 'none'};font-weight:${this.blockData.desc_font_weight === 'bold' ? 'bold' : 'normal'};float:none;margin:0;padding:${this.blockData.text_align !== 'center' ? `0 ${this.blockData.desc_text_padding}px` : '0'};line-height:normal;display:block;}
      .pnx-div{width: ${this.blockData.table_width}${this.blockData.table_width_units ? '%' : 'px'};margin: auto;box-sizing: border-box; padding: 0;}      
      .pnx-logo{display: block;width:56px;height: 14px;overflow: hidden;position: absolute;box-sizing: content-box;right: 0;top: 0;margin: 0;border: 0;padding: 1px;transition: 0.3s;background: url(https://img-cdn.phoenix-widget.com/assets/logo.png) 2px 2px no-repeat #fff;background-size: auto 80%;border-radius: 0 0 0 5px;}
      .pnx-logo:hover{display: block;}
      .pnx-source{color: #a3a3aa;font-family: sans-serif;font-size: 13px;line-height: 16px;text-align: left;display: block;margin: 0;border: 0;padding: 4px 0;border-radius: 0;}
      
      ${this.blockData.desc_position === 0 ? `.pnx-imgbox{float:left;padding: 0;margin: 0 ${this.blockData.img_text_indent}px 0 0;width: ${this.blockData.img_width}px;height: ${this.getImgHeight}px;max-width:auto;}.pnx-cont {text-align:left;max-width: initial;}` : ''}
      ${this.blockData.fixed === 1 ? `.pnx-div{width: ${this.blockData.table_width}px;${this.blockData.table_height ? `height: ${this.blockData.table_height}${this.blockData.table_height_units ? '%' : 'px'};` : ''}}` : ''}
      ${this.blockData.style ? this.blockData.style : ''}
      ${this.blockData.block_img_max_height && parseInt(this.blockData.block_img_max_height, 10) > 0 ? `.pnx-imgbox{height: ${this.blockData.block_img_max_height}px;padding: 0;}.pnx-imgbox img {height: 100%;}` : ''}
      ${this.blockData.img_width < 300 ? `.pnx-infinity .pnx-cont{max-width: ${this.blockData.img_width}px!important;}` : ''}`

      return baseStyles
    },
    addStyle(styles) {
      const css = document.createElement('style')
      css.type = 'text/css'

      if (css.styleSheet) {
        css.styleSheet.cssText = styles
      } else {
        css.appendChild(document.createTextNode(styles))
      }

      document.getElementById('previev321').appendChild(css)
    },
  },
}
</script>

<style lang="scss" scoped>
  .pnx-infinity {
    display: flex;
    flex-wrap: wrap;
  }

  .pnx-infinity .pnx-infinity-item {
    width: calc(100% / 2);
  }

  .pnx-infinity .pnx-infinity-item:nth-child(3n+1),
  .pnx-infinity .pnx-infinity-item:nth-child(3n+2),
  .pnx-infinity .pnx-infinity-item:nth-child(3n+3) {
    width: calc(100% / 3);
  }

  .pnx-infinity .pnx-infinity-item:nth-child(6n+4),
  .pnx-infinity .pnx-infinity-item:nth-child(6n+5) {
    width: calc(100% / 2);
  }

  .pnx-infinity .pnx-infinity-item:nth-child(6n+6) {
    width: 100%;
  }

  .pnx-infinity .pnx-cont {
    text-decoration: none;
    display: block !important;
    margin: auto;
    padding: 0;
    text-align: left;
    max-width: 100%;
  }
</style>
